// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Section, Span, P } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)``;

export const Content = styled.div((props) => css`
    margin-bottom: 12rem;

    ${breakup.large` margin-bottom: 0; `}
`);

export const Label = styled(Span)((props) => css`
    display: block;
    margin-bottom: 1.2rem;

    font-family: ${props.theme.heading};
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;

    color: ${props.theme.bc2};
`);

export const Text = styled(P)(props => css`
    color: ${props.theme.white};
`);

export const Form = styled.div(props => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    transition: all 1s ${props.theme.bezzy};
    filter: blur(${props.isBlurred ? `10px` : `0px`});
`);

export const Email = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
`;

export const EmailMessage = styled.div(props => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    text-transform: none;

    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;
    max-width: calc(100% - 12rem);
    padding: 1.2rem;
    background: ${props.theme.bc1};

    transition: clip-path 1.2s ${props => props.theme.bezzy};
    clip-path: inset(50% 0% 50% 0%);
    transform-origin: center bottom;

    ${breakup.large`
        padding: 2.4rem 2.4rem;
    `}
`);

export const CloseMessage = styled.button(props => css`
    background: transparent;
    cursor: pointer;
    width: 2.4rem;
    max-width: 2.4rem;
    min-width: 2.4rem;

    svg {
        width: 1.6rem;
        height: 1.6rem;
        stroke: ${props.theme.white};
        stroke-width: 2px;
    }
`);

export const ButtonJacket = styled.div(props => css`
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-top: 2.4rem;
`);