// Imports
// ------------
import React, { useRef, useState } from 'react';
import Parser from '@parts/Parser';
import Icon from '@icons';
import Input from '@parts/Input';
import Button from '@parts/Button';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Row, Column } from '@waffl';

// Styles
// ------------
import {
    Jacket,
    Content,
    Text,
    Form,
    Label,
    Email,
    EmailMessage,
    CloseMessage,
    ButtonJacket
} from './styles';


// Custom Form Component
const CustomForm = ({ status, message, onValidated }) => {
    // NOTE • States
    const [active, setActive] = useState(false);
    const [formBlur, setFormBlur] = useState(false);

    // NOTE • Refs
    const emailMessage = useRef();

    // NOTE • Functions
    const handleChange = (e) => {
        setActive(e.target.value.length > 0);
    }

    const handleBlur = (e) => {
        // nothing
    }

    const handleMessageClose = () => {
        setFormBlur(false);
		emailMessage.current.style.clipPath = `inset(50% 0% 50% 0%)`;
	};

	const handleMessageOpen = () => {
        setFormBlur(true);
		emailMessage.current.style.clipPath = `inset(0% 0% 0% 0%)`;
	};


    let email, fname;
    const handleSubmit = () => {
        email &&
        fname &&
        email.value.indexOf('@') > -1 &&
            onValidated({
                EMAIL: email.value,
                FNAME: fname.value,
            });

        handleMessageOpen();
    }

    return (
        <Email isActive={active}>
            <EmailMessage
				ref={emailMessage}
				success={status === 'success'}
				error={status === 'error'}
				sending={status === 'sending'}>
				<Content>
                    <Text>
                        {status === 'sending' && `Sending...`}
                        {status === 'error' && message}
                        {status === 'success' && message}
                    </Text>
                </Content>

				<CloseMessage type='button' onClick={handleMessageClose}>
					<Icon type='close' />
				</CloseMessage>
			</EmailMessage>


            <Form isBlurred={formBlur}>
                <Input
                    ref={(node) => (email = node)}
                    isRequired
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label='Email Address*'
                    type='email'
                    name='emailaddress'
                />

                <Input
                    ref={(node) => (fname = node)}
                    isRequired
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label='First Name*'
                    type='text'
                    name='fname'
                />

                <ButtonJacket>
                    <Button
                        isBtn
                        isNext
                        label='Send'
                        onClick={handleSubmit}
                    />
                </ButtonJacket>
            </Form>
        </Email>
    )
}


// Subscribe Component
// ------------
const ShopSubscribe = () => {
    // NOTE • Mailchimp post URL
    const url = "https://photoscoper.us17.list-manage.com/subscribe/post?u=e215344f13d8257ebeb0222b8&amp;id=cf93b0ac00&amp;f_id=00b550e0f0";

    return (
        <Jacket padTop>
            <Row isExpanded>
                <Column small={12} medium={4} pushMedium={1} mpad>
                    <Content>
                        <Label>Overview</Label>
                        <Parser content="<p>The shop isn't quite ready yet, we're still building it.</p><p>But, I would love to send you a reminder when it launches, so pop your email in the form and I'll let you know!</p>" />
                    </Content>
                </Column>
                <Column small={12} medium={5} pushMedium={2} mpad>
                    <Content>
                        <Label>Subscribe for updates</Label>
                    </Content>

                    <MailchimpSubscribe
                        url={url}
                        render={({ subscribe, status, message }) => (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={(formData) => subscribe(formData)}
                            />
                        )}
                    />
                </Column>
            </Row>
        </Jacket>
    );
}

export default ShopSubscribe;