// Imports
// ------
import React from 'react';
// import Seo from '@parts/Seo';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Hero';
import ShopSubscribe from '@parts/Shop/Subscribe';
import Footer from '@parts/Footer';
// import { graphql } from 'gatsby';
import { observer } from 'mobx-react-lite';

// Page
// ------
const ShopPage = () => (
	<>
		{/* <Seo meta={data.home.seoMeta} /> */}

        <SmoothScroll id="scrollContainer">
            <Hero title="Shop for my best images" sub="Photos &amp; Merch" />
            <ShopSubscribe />
            <Footer />
        </SmoothScroll>
	</>
);

export default observer(ShopPage);